<template>

</template>


<script>
export default {
  name: 'Total',
  props: {
    msg: String
  }
}
</script>

<style scoped>

</style>